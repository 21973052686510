@import "~bootstrap/scss/mixins/breakpoints";
.bb {
  width: 100%;
  scroll-margin-top: 80px;

  p,
  ul,
  blockquote {
    max-width: 800px;
  }

  img {
    display: inline-block;
  }

  @media (min-width: $screen-md) {
    font-size: 18px;
    line-height: 27px;
  }

  @include media-breakpoint-up(lg) {
    scroll-margin-top: 60px;
  }
}
