@import '../../../../../styles/basics';

.field-type-select.field-style-standalone {
  &.clearable {
    padding-right: 150px;
  }

  // Hide vertical red line and error icon.
  &.error {
    border-left: none;
    padding-left: inherit;
  }

  [data-component="field-right-icon"] {
    display: none;
  }

  .select__input:focus {
    box-shadow: none;
  }

  .select__control {
    color: $slate-1200;
    font-size: 16px;
    border: 1px solid $gray-1000;
    padding: 17px 19px;
    position: relative;
    line-height: 24px;
    transition: all 0.2s;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    &:hover {
      cursor: pointer;
      border-color: $gray-1000;
    }

    .select__value-container {
      padding: 0 16px 0 0;
      overflow: visible;

      .select__single-value {
        font-weight: $font-weight-bold;
        font-size: 18px;
        color: $turquoise-1000;
      }
    }

    .select__placeholder {
      margin: 0;
      font-weight: $font-weight-bold;
      color: $turquoise-1000;
      font-size: 18px;
    }

    /* input wrapper */
    .css-1g6gooi {
      margin: 0;
      padding: 0;
    }

    .select__indicators {
      svg {
        width: 16px;
        height: 16px;
        pointer-events: none;

        path {
          stroke: $gray-1000;
        }
      }
    }

    .clear {
      color: $gray-1000;
      position: absolute;
      right: -125px;
      top: 16px;

      svg {
        height: 10px;
        width: 10px;
        margin-top: -1px;
        margin-left: 3px;

        & > g > path {
          stroke: $white;
        }
      }

      &:hover {
        color: $turquoise-1000;

        svg g g path {
          stroke: $turquoise-1000;
        }
      }
    }

    .select__indicator-separator {
      display: none;
    }
  }

  .select__menu {
    margin: 0;
    padding: 0;
    border-left: 1px solid $turquoise-1000;
    border-right: 1px solid $turquoise-1000;
    border-bottom: 1px solid $turquoise-1000;
    border-top: 0;
    box-shadow: $shadow2;
    z-index: 1000;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    & > div {
      padding: 0;
      margin: 0;
    }

    .select__option {
      padding: 14px 16px;
      border-bottom: 1px solid $slate-200;
      color: $slate-1200;

      &:last-child {
        border: none;
      }

      &.select__option--is-selected,
      &.select__option--is-focused {
        background: $turquoise-1000;
        color: $white;
        cursor: pointer;
      }
    }
  }

  .opened {
    & + label {
      display: none;
    }
  }

  .has-value {
    & + label {
      display: none;
    }
  }

  &.active {
    .select__control {
      border-color: $turquoise-1000;

      .select__indicators {
        path {
          stroke: $turquoise-1000;
        }
      }
    }
  }

  &.error {
    label {
      color: $red-1000;
    }

    .select__control,
    .select__menu {
      border-color: $red-1000;
    }

    svg {
      path {
        stroke: $red-800 !important;
      }
    }

    [data-component="field-error"] svg path {
      stroke: inherit !important;
      fill: $red-1000;
    }
  }
}
