// Generally used to space out standalone body blocks.
@mixin bb-standalone-spacing() {
  margin-top: $bb-mobile-standalone-margin;
  margin-bottom: $bb-mobile-standalone-margin;
  @include media-breakpoint-up(md) {
    margin-top: $bb-desktop-standalone-margin;
    margin-bottom: $bb-desktop-standalone-margin;
  }
}

@mixin bb-standalone-spacing-new() {
  margin-top: $bb-mobile-standalone-margin-new;
  margin-bottom: $bb-mobile-standalone-margin-new;
  @include media-breakpoint-up(md) {
    margin-top: $bb-desktop-standalone-margin-new;
    margin-bottom: $bb-desktop-standalone-margin-new;
  }
}

// Used for blocks inside of text which don't have to
// stand out from the text.
@mixin bb-text-spacing() {
  margin-top: $bb-mobile-text-margin;
  margin-bottom: $bb-mobile-text-margin;
  @include media-breakpoint-up(md) {
    margin-top: $bb-desktop-text-margin;
    margin-bottom: $bb-desktop-text-margin;
  }
}

// Used for blocks inside of text which have to
// stand out from the text.
@mixin bb-standalone-text-spacing() {
  margin-top: $bb-mobile-standalone-text-margin;
  margin-bottom: $bb-mobile-standalone-text-margin;
  @include media-breakpoint-up(md) {
    margin-top: $bb-desktop-standalone-text-margin;
    margin-bottom: $bb-desktop-standalone-text-margin;
  }
}

// Need to "suck" the body blocks around a block with frayed edge
// under the frayed edge visually.
@mixin bb-negative-spacing() {
  margin: -12px 0;
  position: relative;
  z-index: 4000;
}

// Default spacing for Heading elements.
@mixin bb-heading-spacing() {
  margin-bottom: $bb-mobile-heading-margin;
  @include media-breakpoint-up(md) {
    margin-bottom: $bb-desktop-heading-margin;
  }
}

@mixin bb-with-background-padding() {
  padding-bottom: $bb-with-background-mobile-padding;
  padding-top: $bb-with-background-mobile-padding;
  @include media-breakpoint-up(md) {
    padding-bottom: $bb-with-background-desktop-padding;
    padding-top: $bb-with-background-desktop-padding;
  }
}

@mixin bb-activism-spacing() {
  margin-top: 48px;
  margin-bottom: 48px;
  @include media-breakpoint-up(md) {
    margin-top: 88px;
    margin-bottom: 88px;
  }
}

@mixin bb-activism-with-background-padding() {
  padding-top: 48px;
  padding-bottom: 48px;
  @include media-breakpoint-up(md) {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}