@import '../../../../styles/basics';

.load-more-button {
  &__button {
    display: block;
    font-weight: $font-weight-bold;
    width: 80%;
    margin: 20px auto;
    border-radius: 4px;
    background-color: $gray-100;
    border: none;
    color: $turquoise-1000;
    padding: 12px 28px;
    transition: linear 0.3s;
    svg {
      margin-left: 5px;
    }
    &:hover,
    &:focus,
    &.active {
      background-color: $turquoise-100;
      border: none;
      color: $turquoise-1200;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}
