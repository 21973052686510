@import "../../../../styles/gifts/sass/basics";
// TODO: Uncomment it when storybook updated to version 8.
//@value modal-backdrop, modal-body, in, modal-content, modal-dialog from '../../../Popup/index.module.scss';

@keyframes :local(popupAnimationOverlay) {
  0% {
    background-color: transparent;
  }
  100% {}
}

@keyframes :local(popupAnimationBody) {
  0% {
    transform: translateY(-100%);
  }
  100% {}
}

.santa-letter-modal {
  // TODO: Replace styles below with commented styles when storybook updated to version 8.
  &.santa-letter-modal__important {
    background-color: $black-50;
    &:local {
      animation-iteration-count: 1;
      animation: ease-out popupAnimationOverlay 0.3s;
    }
    > div {
      margin: 12px;
      width: auto;
      background: none;
      transform: translateY(0);
      animation: ease-out popupAnimationBody 0.3s;

      @media (min-width: 370px) {
        margin: 20px;
      }

      > div {
        border: none;
        box-shadow: none;
        margin: 0;
        padding: 0;
        max-height: 100dvh;
        background: none;
        > div {
          padding: 0;
        }
      }
    }
  }

  // TODO: Uncomment it when storybook updated to version 8. Update to use animation.
  //&.modal-backdrop.in {
  //  background: $black-50;
  //}
  //.modal-content {
  //  border: none;
  //  box-shadow: none;
  //  margin: 0;
  //  padding: 0;
  //  max-height: 100dvh;
  //}
  //.modal-body {
  //  padding: 0;
  //}
  //.modal-dialog {
  //  margin: 12px;
  //  width: auto;
  //}
  // @media (min-width: 370px) {
  //  .modal-dialog {
  //    margin: 20px;
  //  }
  //}

  .santa-letter-modal {
    &__content {
      background: $white;
      border-radius: 12px;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding: 40px 28px;
      text-align: center;
    }

    &__image {
      background-image: url('/static/Gifts/images/elf-with-envelope.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      min-width: 100px;
      height: 136px;
    }

    &__title {
      font-weight: $font-weight-bold;
      color: $slate-1200;
      font-size: 28px;
      margin-bottom: 24px;

      strong {
        color: $red-1000;
      }
    }

    &__description {
      font-size: 18px;
      margin-bottom: 24px;
      color: $slate-1200;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      > * {
        margin: 0;
        text-decoration: none;
        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
}
