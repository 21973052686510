@import '../../../../styles/basics';

.field-type-select.field-style-normal {
  [data-component="field-right-icon"] {
    display: none;
  }

  .select__input:focus {
    box-shadow: none;
  }

  .select__control {
    color: $slate-1200;
    width: 100%;
    font-size: 16px;
    border: 1px solid $slate-1000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 10px 14px;
    line-height: 24px;
    transition:  border-color 0.2s;
    box-shadow: none;
    &:hover {
      cursor: pointer;
    }

    .select__value-container {
      padding: 0 16px 0 0;
      > div {
        margin: 0;
        padding: 0;
      }
    }

    .select__placeholder {
      margin: 0;
      color: $slate-400;
    }

    .select__indicators {
      svg {
        width: 16px;
        height: 16px;
        pointer-events: none;

        path {
          stroke: $slate-1000 !important;
        }
      }

      .select__clear-indicator {
        padding: 4px;
      }
    }

    .select__indicator-separator {
      display: none;
    }
  }

  .select__menu {
    margin: 0;
    padding: 0;
    border-left: 1px solid $turquoise-1000;
    border-right: 1px solid $turquoise-1000;
    border-bottom: 1px solid $turquoise-1000;
    border-top: 0;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
    box-shadow: $shadow2;
    z-index: 1000;

    & > div {
      padding: 0;
      margin: 0;
    }

    .select__option {
      padding: 14px 16px;
      border-bottom: 1px solid $slate-200;
      color: $slate-1200;

      &:last-child {
        border: none;
      }

      &.select__option--is-selected,
      &.select__option--is-focused {
        background: $turquoise-1000;
        color: $white;
        cursor: pointer;
      }
    }
  }

  .opened {
    .select__control {
      border-color: $turquoise-1000;
      -webkit-border-radius: 5px 5px 0 0;
      -moz-border-radius: 5px 5px 0 0;
      border-radius: 5px 5px 0 0;
      box-shadow: 0 0 0 4px $turquoise-100;

      .select__indicators {
        path {
          stroke: $turquoise-1000 !important;
        }
      }
    }

    &.menu-top {
      .select__control {
        -webkit-border-radius: 0 0 5px 5px;
        -moz-border-radius: 0 0 5px 5px;
        border-radius: 0 0 5px 5px;
      }
      .select__menu {
        border-top: 1px solid $turquoise-1000;
        border-bottom: 0;
        -webkit-border-radius: 5px 5px 0 0;
        -moz-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
      }
    }
  }

  &.has-value:not(.active):not(.error) {
    .select__control {
      border-color: $turquoise-1000;
    }
  }

  label {
    font-weight: $font-weight-bold;
    cursor: text;
    transition: all 0.2s;
    color: $black;
    font-size: 16px;
    margin-bottom: 5px;

    .validation {
      font-weight: $font-weight-regular;
      font-size: 14px;
      color: $slate-1000;
    }
  }

  &.active {
    label {
      color: $turquoise-1000;
      .validation {
        color: $turquoise-1000;
      }
    }

    .select__control {
      border-color: $turquoise-1000;
      border-width: 2px;
      margin: -1px;
      box-shadow: 0 0 0 4px $turquoise-100;

      .select__indicators {
        path {
          stroke: $turquoise-1000 !important;
        }
      }
    }
    .select__menu {
      border-width: 2px;
      margin: -1px;
    }
  }

  &.error {
    label {
      color: $red-1000;
      .validation {
        color: $red-1000;
      }
    }

    .select__control,
    .select__menu {
      border-color: $red-800 !important;
      box-shadow: none;
    }

    &.active {
      .select__control {
        box-shadow: 0 0 0 4px $red-100;
      }
    }

    svg {
      path {
        stroke: $red-800 !important;
      }
    }

    [data-component="field-error"] svg path {
      stroke: inherit !important;
      fill: $red-1000;
    }
  }
}
