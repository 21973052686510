@import '../../../../styles/basics';


.bb-hub-search {
  font-size: 16px;

  .back-button {
    color: $turquoise-1200;
    margin-top: 36px;
    margin-bottom: 36px;
    display: inline-block;
    padding-left: 26px;
    position: relative;

    &:hover {
      text-decoration: none;
    }

    &:before {
      content: '';
      background-image: url('/static/icons/arrow.svg');
      background-size: contain;
      position: absolute;
      width: 14px;
      height: 14px;
      left: 0;
      top: 5px;
      display: inline-block;
    }
  }

  .title-and-search-container {
    margin-bottom: 5px;
    min-height: 56px;
    position: relative;

    .search-title {
      font-size: 20px;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }
  }

  .info-and-sort-container {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px $slate-400;
    margin-bottom: 24px;

    @include media-breakpoint-up(md) {
      border-bottom: none;
    }
  }

  .hub-search-results {

    &.hub-search-results-home {
      margin-top: 34px;
    }

    :global {
      .hub-search-results-container {
        padding-left: 0;
        padding-right: 0;
      }

      .d-flex {
        flex-direction: column;
      }

      .hub-search-results-list {
        flex-grow: 1;
      }

      .results-pagination-wrapper {
        display: none;
        justify-content: center;
      }

      .results-pagination {
        font-weight: $font-weight-bold;
        font-size: 16px;
        margin-top: 45px;
        margin-bottom: 110px;
        display: flex;
        .pagination-item {
          display: inline-flex;
          border-radius: 4px;
          width: 48px;
          height: 48px;
          justify-content: center;
          align-items: center;
          background-color: $gray-100;
          color: $slate-1000;
          margin: 0 5px;
          transition: all 0.3s ease 0s;

          &:hover,
          &.active-item {
            background-color: $turquoise-1200;
            color: $white;
            text-decoration: none;
          }
        }

        .pagination-ellipsis {
          width: 48px;
          height: 48px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        .pagination-nav {
          display: inline-flex;
        }

        .pagination-next,
        .pagination-previous {
          margin: 0 5px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: $turquoise-1200;
          border-radius: 4px;
          height: 48px;
          padding: 0 10px 0 15px;
          a {
            color: $turquoise-1200;
          }
          &:hover {
            background-color: $gray-100;
          }
        }

        .pagination-next {
          padding: 0 5px 0 15px;
        }

        .pagination-previous {
          padding: 0 15px 0 5px;
        }

      }

      @include media-breakpoint-up(md) {
        .d-flex {
          flex-direction: row;
        }
        .hub-search-results-list {
          padding: 0 0 0 1rem;
        }
      }

      @include media-breakpoint-up(xl) {
        .results-pagination-wrapper {
          display: flex;
        }
      }

    }
  }

  .no-results {
    :global {
      .hide-when-no-results {
        display: none !important;
      }

      .hide-when-no-results-mobile {
        display: none;

        @include media-breakpoint-up(md) {
          display: initial;
        }
      }

      .hub-search-results-list {
        padding: 0;
      }
    }
  }

  .results-list {
    opacity: 1;
    transition: opacity 0.3s;

    &.loading {
      opacity: 0.3;
    }
  }


  .featured {
    h2 {
      display: none;
    }
  }
  .featured-home {
    margin-bottom: 28px;
    @include media-breakpoint-up(md) {
      margin-bottom: 42px;
    }
  }
  .all-resources-heading {
    border-bottom: 2px solid $gray-400;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .h2 {
    margin-bottom: 3px;
    @include media-breakpoint-up(md) {
      margin-bottom: 8px;
      font-size: 22px;
    }
  }
}

.kh-load-more-button {
  @include media-breakpoint-up(xl) {
    display: none !important;
  }
}
