@import '../../../../styles/basics';

.field-type-checkboxes {
  margin-bottom: 24px;

  &.error {
    // Add vertical red line.
    border-left: 2px solid $red-1000;
    padding-left: 10px;
  }

  & > label {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: $font-weight-bold;

    .validation {
      font-weight: $font-weight-regular;
      font-size: 14px;
      color: $slate-1000;
    }
  }

  .checkbox-item {
    position: relative;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &.with-icon {
      margin-bottom: 16px;

      .icon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 28px;
        flex-direction: column;
        display: flex;
        justify-content: center;
      }

      svg {
        max-width: 28px;
        max-height: 28px;
      }

      label {
        margin-left: 48px;
        cursor: pointer;
        padding: 12px;
        line-height: 24px;
        display: block;
        font-size: 16px;
        border: 2px solid $gray-1000;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        transition: 0.3s border;

        &:hover {
          border-color: $turquoise-1000;

          .indicator {
            border-color: $turquoise-1000;
          }
        }

        .indicator {
          left: 61px;
          top: 13px;
        }
      }
    }

    &.selected {
      label {
        color: $turquoise-1000;
        border-color: $turquoise-1000;
      }

      .indicator {
        background-image: url('/static/icons/checkmark.svg');
        background-color: $turquoise-1000;
        border-color: $turquoise-1000;
      }

      path[fill='#767676'] {
        fill: $turquoise-1000;
      }
    }

    label {
      cursor: pointer;
      margin: 12px 0;
      display: inline-block;
      line-height: 24px;
      font-size: 16px;
      transition: 0.3s border;

      &:hover {
        .indicator {
          border-color: $turquoise-1000;
        }
      }

      input {
        display: none;
      }

      .indicator {
        background: no-repeat 3px 4px;
        height: 24px;
        width: 24px;
        position: absolute;
        left: 0;
        top: 12px;
        border: 1px solid $gray-1000;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        transition: 0.3s all;
      }

      .label {
        margin-left: 36px;
      }
    }
  }
}

.field-type-checkboxes .checkbox-item.with-background {
  > label {
    margin: 1px 0;
    padding: 12px 14px 12px 12px;
    transition: 0.3s;
    background-color: transparent;

    &:hover {
      background-color: $turquoise-100;
      border-radius: 5px;
    }
  }

  .indicator {
    left: 12px;
  }

  &.selected > label {
    background-color: $turquoise-100;
    border-radius: 5px;
  }
}
