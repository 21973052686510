///////////////////////////////
///// BODY BLOCKS SPACING /////
///////////////////////////////

$bb-desktop-standalone-margin: 60px;
$bb-mobile-standalone-margin: 48px;

// New recommended values for most of the blocks.
// See https://www.pivotaltracker.com/story/show/183697777/comments/234825013
$bb-desktop-standalone-margin-new: 48px;
$bb-mobile-standalone-margin-new: 24px;

$bb-desktop-text-margin: 24px;
$bb-mobile-text-margin: 16px;

$bb-desktop-standalone-text-margin: 40px;
$bb-mobile-standalone-text-margin: 24px;

$bb-desktop-heading-margin: 24px;
$bb-mobile-heading-margin: 18px;

$bb-with-background-desktop-padding: 60px;
$bb-with-background-mobile-padding: 36px;
