@import "../../../styles/gifts/sass/basics";
@value shipping-info-message from '../../ShippingInfoMessage/index.module.scss';

.bb-santa-letter {
  @include bb-standalone-spacing-new();

  &__heading {
    text-align: center;
    font-size: 28px;
    color: $green-1000;
    max-width: 300px;
    margin: 0 auto 24px;
  }

  &__letter-wrapper {
    aspect-ratio: 1257/1771; // Half of the size of the original santa-letter.png
    max-width: 628px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: -35px 29px 13px 0px rgba(0, 0, 0, 0.00), -22px 19px 12px 0px rgba(0, 0, 0, 0.01), -13px 11px 10px 0px rgba(0, 0, 0, 0.05), -6px 5px 7px 0px rgba(0, 0, 0, 0.09), -1px 1px 4px 0px rgba(0, 0, 0, 0.10);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($white, .75);
      opacity: 0;
      z-index: 2;
      pointer-events: none;
      transition: opacity 0.5s ease-out;
    }

    &--updating {
      &:after {
        opacity: 1;
        transition: opacity 0s ease-out;
      }

      .bb-santa-letter__loader {
        z-index: 3;
        opacity: 1;
      }

      .bb-santa-letter__letter-text {
        transition: color 0s ease-out;
        color: #999999;
      }

      .bb-santa-letter__letter-value {
        color: #231F20;
      }
    }

    &--letter-zoomed {
      background: rgba(#000, .25);
      cursor: zoom-in;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -28px;
    margin-left: -28px;
    z-index: -1;
    opacity: 0;
  }

  &__letter {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 1257px; // Half size of original width, 2515 / 2.
    height: 1771px; // Half size of original height, 3543 / 2.
    transform-origin: top left;
    // Transform property is calculated and added by JS:
    // transform: scale(scaleFactor); where scaleFactor is "letter wrapper width" / (2515 / 2).
    will-change: transform, translate, top, left, opacity;
  }

  // Letter content.
  &__letter-template {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff3c2; // Main background color of the letter.
    background-image: url('/static/Gifts/images/santa-letter.png');
    background-size: 100% auto;
    background-position: top left;
    background-repeat: no-repeat;
  }

  &__letter-text {
    margin: 75px;
    width: 698px;
    font-size: 25px;
    font-family: "Atma";
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0.5px;
    color: #231F20;
    transition: color 1.5s ease-out;

    p {
      font-size: 25px;
      line-height: 37px;
      margin-bottom: 19px;
      letter-spacing: 0.5px;
      word-spacing: 1.6px;
    }
  }

  &__letter-signature {
    width: 281px;
    height: 63px;
    margin: 12px 0 42px -3px;
    fill: currentColor;
  }

  // Zoom info text.
  &__zoom-info {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    max-width: 628px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    color: $slate-800;
    gap: 4px;

    svg {
      margin-top: 1px;
      fill: currentColor;
    }

    &--desktop {
      position: absolute;
      top: -48px;
      left: 568px;
    }
  }

  &__actions {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 0;
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 16px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    z-index: 4;
    pointer-events: none;

    &--no-sticky {
      position: static;
    }

    button {
      pointer-events: auto;
    }
  }

  &__customize {
    display: flex;
    flex-grow: 1;

    button {
      flex-grow: 1;
      display: block;

      svg {
        margin-right: 8px;
        fill: currentColor;
      }
    }
  }

  &__submit {
    display: flex;
    flex-grow: 1;

    button {
      flex-grow: 1;
      display: block;
    }
  }

  // Preview modal content.
  &__letter-preview-wrapper {
    padding: 0;
    aspect-ratio: 1257/1771;
    overflow: hidden;
    max-width: calc(100dvw - 40px);
    max-height: calc(100dvh - 112px);
  }

  &__letter-preview {
    position: relative;
    transform-origin: top left;
    width: 1257px;
    height: 1771px;
    // Transform property is calculated and added by JS:
    // transform: scale(previewScaleFactor); where previewScaleFactor is "letter preview wrapper width" / (2515 / 2).
  }

  // Preview modal.
  &__preview-overlay {
    padding: 0 !important;
    background: rgba(#000, .5) !important;
    overflow: hidden;
    // Override min-height: 100vh which breaks height on mobiles.
    min-height: 100dvh !important;
    height: 100dvh !important;
  }

  &__preview-modal {
    margin: 0 !important;
    max-height: 100dvh;

    & > div {
      background: none;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      padding: 20px 20px 92px;
      position: relative;
      display: flex;
      justify-content: center;

      // Modal body.
      & > div:not(.bb-santa-letter__preview-modal-close) {
        padding: 0;
      }
    }
  }

  &__preview-modal-close {
    position: absolute;
    bottom: 20px;
  }

  // The form.
  &__form-mobile {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  &__form-submit--normal,
  &__form-submit--modal {
    .bb-santa-letter__form-submit-button--disabled {
      &,
      &:hover,
      &.hover,
      &:focus-visible {
        background: $gray-200;
        border-color: $gray-200;
        color: $gray-600;
        cursor: default;
      }
    }
  }

  &__form-submit--normal {
    display: flex;
    justify-content: flex-end;

    button.bb-santa-letter__form-submit-button {
      margin-left: auto;
    }
  }

  &__form-submit--modal {
    display: flex;

    button {
      width: 100%;
    }
  }

  &__form-submit-tooltip {
    :global(.react-tooltip) {
      font-size: 12px;
      max-width: 280px;
      padding: 12px 16px;
      background: $turquoise-1200;
      animation: 0.3s ease-out 0s 1 fadeIn;
    }
  }

  // Mobile form modal.
  &__overlay {
    padding: 0 !important;
    background: rgba(#000, .5) !important;
    align-items: flex-end !important;
    overflow: hidden;
    // Override min-height: 100vh which breaks height on mobiles.
    min-height: 100dvh !important;
    height: 100dvh !important;
  }

  &__modal {
    margin: 0 !important;
    max-height: 100dvh;
    animation: .3s ease-out 0s 1 modalTransition;
    overflow-y: auto;

    & > div {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 22px;
      position: relative;
    }
  }

  // A common wrapper for close button and tooltip, without it tooltip jumps when displayed.
  &__modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;

    button {
      border: none;
      padding: 8px;
      margin: 0;
      z-index: 2;
    }
  }

  &__modal-close-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    :global(.react-tooltip) {
      font-size: 12px;
      max-width: 205px;
      padding: 12px 16px;
      background: $turquoise-1200;
      animation: 0.3s ease-out 0s 1 fadeIn;
    }
  }

  &__modal-close-tooltip-actions {
    margin-top: 20px;
    display: flex;
    gap: 12px;
  }

  // Override margins for the ShippingInfoMessage component
  .shipping-info-message {
    margin-bottom: 56px;
  }

  @include media-breakpoint-down(xl) {
    &__heading {
      animation: 1s ease-out 0s 1 letterOpacity, 1s cubic-bezier(.17,.69,.21,1.06) 0s 1 headingTransition;
    }

    &__letter-wrapper {
      animation: 1s ease-out 0s 1 letterOpacity, 1s cubic-bezier(.17,.69,.21,1.06) 0s 1 letterTransition;
    }

    &__customize {
      animation: 1s ease-out 0s 1 letterOpacity, 1s cubic-bezier(.17,.69,.21,1.06) 0s 1 buttonTransition;
    }
  }

  @include media-breakpoint-down(md) {
    & {
      margin-top: 36px;
    }
  }

  @include media-breakpoint-down(sm) {
    &__container {
      padding-left: 28px;
      padding-right: 28px;
    }
  }

  @include media-breakpoint-up(sm) {
    &__customize,
    &__submit,
    &__form-submit--modal {
      flex-grow: 0;
      justify-content: center;

      button {
        flex-grow: 0;
        min-width: 240px;
        width: auto;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-top: 90px;

    &__heading {
      font-size: 36px;
      max-width: 440px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__container {
      position: relative;
    }

    &__content {
      padding-left: 628px + 48px;
      min-height: 885px;
      max-width: 520px + 628px + 48px;
      margin-left: auto;
    }

    &__heading {
      text-align: left;
      max-width: none;
      margin-left: 0;
    }

    &__letter-wrapper {
      position: absolute;
      left: 70px; // Containers left padding.
      top: 0;
      width: 628px;
      border-radius: 8px;
      box-shadow: none;
    }

    &__letter {
      transform-origin: center center;

      &:not(.bb-santa-letter__letter--zoomed) {
        top: -50% !important;
        left: -50% !important;
      }

      // Getting rid of animation on initial loading.
      &--initialized {
        // Using different positioning properties for hover zoom and field focus zoom,
        // so that they can be animated separately, otherwise it doesn't look good.
        transition: transform 0.3s ease-out, translate 0.3s ease-out;
      }

      // Hover zoom.
      &--zoomed {
        transform: scale(0.75) !important;
      }

      &--zoomed-name:not(.bb-santa-letter__letter--zoomed),
      &--zoomed-present:not(.bb-santa-letter__letter--zoomed),
      &--zoomed-post-scriptum:not(.bb-santa-letter__letter--zoomed),
      &--not-filled:not(.bb-santa-letter__letter--zoomed) {
        .bb-santa-letter__letter-text {
          color: #999999;
          transition: color 0s;
        }

        .bb-santa-letter__letter-value {
          color: #231F20;
        }
      }

      // Field focus zoom.
      &--zoomed-name:not(.bb-santa-letter__letter--zoomed) {
        transform: scale(0.75) !important;
        translate: 150px 220px;
      }

      &--zoomed-present:not(.bb-santa-letter__letter--zoomed) {
        transform: scale(0.75) !important;
        translate: 150px 0px;
      }

      &--zoomed-post-scriptum:not(.bb-santa-letter__letter--zoomed) {
        transform: scale(0.75) !important;
        translate: 150px -200px;
      }
    }

    .bb-santa-letter__letter-text {
      transition: color 1s ease-out;
    }
    // Override margins for the ShippingInfoMessage component
    .shipping-info-message {
      margin-top: 56px;
    }
  }

  @keyframes letterOpacity {
    0% {
      opacity: 0;
    }
    35% {
      opacity: 0.05;
    }
    90% {
      opacity: 1;
    }
  }

  @keyframes headingTransition {
    0% {
      transform: translateY(-200%);
    }
    50% {
      transform: translateY(-200%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes letterTransition {
    0% {
      transform: translateY(200%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes buttonTransition {
    0% {
      transform: translateY(200%);
    }
    50% {
      transform: translateY(200%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes modalTransition {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

}
