//
// Variables
// --------------------------------------------------

//** Disabled cursor for form controls and buttons.
$cursor-disabled: default;

$zindex-tooltip: 1070;

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;


// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;


// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);

$screen-xlg: 1470px;

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;

$container-xlg: 1420px;

$panel-default-border: #ddd;


//** Abbreviations and acronyms border color
$component-offset-horizontal: 180px;
$dl-horizontal-offset: $component-offset-horizontal;

// Variables for CNET bootstrap, to be able to reuse CNET mix-ins
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
) !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// Main font for the site is IBM Plex Sans.
$font-family-stack-sans: 'IBM Plex Sans', 'Helvetica', 'Arial', 'sans-serif';

// If introducing new font weight, make sure it's loaded from Google Fonts.
$font-weight-regular: 400;
$font-weight-bold: 700;

$font-family-base: $font-family-stack-sans;
$text-color: $slate-1200;
$text-weight: $font-weight-regular;

$font-size-base: 16px;
$font-size-large: floor(($font-size-base * 1.5)); // ~24px
$font-size-big: floor(($font-size-base * 1.25)); // ~20px
$font-size-lead: floor(($font-size-base * 1.125)); // ~18px
$font-size-small: floor(($font-size-base * 0.875)); // ~14px
$font-size-xs: floor(($font-size-base * 0.75)); // ~12px

$font-size-h1: floor(($font-size-base * 2.25)); // ~36px
$font-size-h2: floor(($font-size-base * 1.875)); // ~30px
$font-size-h3: floor(($font-size-base * 1.625)); // ~26px
$font-size-h4: floor(($font-size-base * 1.375)); // ~22px
$font-size-h5: $font-size-lead; // ~18px
$font-size-h6: $font-size-base; // ~16px

$headings-color: $text-color;
$font-weight-h1: $font-weight-regular;
$font-weight-h2: $font-weight-regular;
$font-weight-h3: $font-weight-regular;
$font-weight-h4: $font-weight-regular;
$font-weight-h5: $font-weight-regular;
$font-weight-h6: $font-weight-regular;

$font-style-h1: normal;
$font-style-h2: normal;
$font-style-h3: normal;
$font-style-h4: normal;
$font-style-h5: normal;
$font-style-h6: normal;

$text-transform-h1: none;
$text-transform-h2: none;
$text-transform-h3: none;
$text-transform-h4: none;
$text-transform-h5: none;
$text-transform-h6: none;

$quote-font-size: $font-size-lead;
$quote-border: 5px solid $gray-200;
$quote-text-transform: none;
$quote-font-weight: $font-weight-regular;
$quote-font-style: normal;
$quote-font-color: $gray-1000;
$quote-author-font-color: $gray-400;

$line-height-base: 1.5;
$headings-line-height: 1.3;
$line-height-computed: ceil(($font-size-base * $line-height-base)); // ~24px

$link-color: $green-600;
$link-hover-color: $green-600;

$text-gray: $gray-400;

$hr-border: $gray-200;

$border-radius-big: 8px;
$border-radius-base: 5px;
$border-radius-small: 4px;

// Map
$map-height-tablet: 400px;
$map-height-mobile: 300px;

//== Forms
//

$input-height: 48px;
$input-height-sm: 36px;
$input-font-size: $font-size-small;
$input-bg: $white;
$input-color: $text-color;
$input-border-color: $slate-1000;
$input-focus-border-color: $turquoise-1000;
$input-error-border-color: $red-1000;
$input-border-radius: $border-radius-base;
$input-color-placeholder: $slate-400;
$input-icon-size: 20px;

$checkbox-radio-border-color: $gray-200;
$checkbox-checked-color: $green-600;
$radio-checked-color: $green-600;
$checkbox-radio-label-color: $text-color;
$checkbox-radio-label-font-size: $font-size-small;
$disabled-color: $gray-400;

$search-icon-size: 20px;

//== Buttons
//

$btn-font-size: $font-size-small;
$btn-sm-font-size: $font-size-xs;
$btn-text-transform: none;
$btn-font-weight: $font-weight-regular;
$btn-sm-font-weight: $font-weight-bold;
$btn-font-color-default: $text-color;
$btn-font-color-alt: $white;

$btn-height: 48px;
$btn-sm-height: 36px;

$btn-default-bg: $gray-100;
$btn-primary-bg: $green-600;
$btn-success-bg: $blue-800;
$btn-info-bg: $blue-400;
$btn-warning-bg: $yellow-1200;
$btn-danger-bg: $pink-1200;
$btn-light-bg: $white;

$scroll-top-btn-icon-size: 24px;
$scroll-top-btn-color: $gray-1200;
$scroll-top-btn-bg: $white;
$scroll-top-btn-hover-color: $gray-400;

$social-btn-size: $font-size-small;

//== Tables
//

$table-border-color: $gray-200;
$table-cell-padding: 15px;
$table-bg-accent: $gray-100;

//== Navbar / Logo / Cart
//

$navbar-zindex: 9900;
$navbar-min-height: 114px;
$navbar-min-height-mobile: 98px;
$navbar-bg: $white;

$image-logo-width: 115px;
$image-logo-sm-width: 42px;
$flag-size: 24px;
$dropdown-arrow-size: 22px;

$lang-dropdown-width: 106px;
$lang-dropdown-bg: $white;

$toolbar-width-desktop: 70px;
$toolbar-width-mobile: 124px;
$toolbar-toggle-icon-size: 24px;

$nav-link-font-size: floor(($font-size-base * 0.875)); // ~14px
$nav-link-color: $gray-1000;
$nav-link-hover-color: $gray-200;

$submenu-width: 240px;
$submenu-bg-color: $white;
$submenu-border: 1px solid $hr-border;
$submenu-link-font-size: floor(($font-size-base * 0.75)); // ~12px

// Shopping Cart Dropdown
$cart-dropdown-width: 320px;
$cart-dropdown-bg-color: $white;
$cart-dropdown-border: 1px solid $hr-border;
$cart-dropdown-thumb-width: 65px;

// Shopping Cart
$shopping-cart-thumb-width: 150px;
$shopping-cart-thumb-width-mobile: 80px;

// Search Button
$search-button-icon-size: 20px;

// Mobile Menu
$mobile-menu-bg-color: $white;

//== Tabs
//

$nav-tabs-font-size: floor(($font-size-base * 0.875)); // ~14px
$nav-tabs-link-color: $gray-400;
$nav-tabs-link-active-color: $gray-1200;

//== Filters
//

$nav-filters-font-size: floor(($font-size-base * 0.975)); // ~15px
$nav-filters-link-color: $gray-1200;
$nav-filters-link-active-color: $pink-800;
$nav-filters-link-active-border-color: $pink-1200;
$nav-filters-count-color: $gray-400;

//== Panels and Accordions
//

$panel-bg: transparent;
$panel-heading-font-size: $font-size-h5; // 18px
$panel-heading-font-color: $gray-400;
$panel-heading-hover-color: $gray-1200;
$panel-default-heading-bg: transparent;
$panel-default-text: $gray-1000;

// == Shop
//

$shop-item-thumb-bg: $white;
$shop-item-title-size: $font-size-base;
$shop-item-title-color: $gray-400;
$shop-item-wishlist-bg: $white;
$shop-item-wishlist-color: $gray-400;
$shop-item-wishlist-hover-color: $gray-1200;

$add-to-cart-color: $white;
$add-to-cart-bg: $green-600;

$shop-label-height: 24px;
$shop-label-bg: $white;
$shop-label-font-size: $font-size-xs;

$shop-rating-size: 13px;

// Single Product
$product-gallery-preview-max-width: 660px;
$product-gallery-thumb-width: 80px;
$product-meta-font-size: $font-size-small;
$product-meta-color: $gray-400;
$product-meta-hover-color: $gray-1200;

// Reviews
$review-author-ava-size: 98px;
$review-body-font-size: $font-size-small;

// == Blog
//

$blog-post-meta-color: $gray-400;
$blog-post-meta-font-size: $font-size-small;
$blog-post-title: floor(($font-size-base * 1.375)); // ~22px

// Single Post
$single-post-wrap-bg: $white;

//== Widgets
//

$sidebar-max-width: 300px;
$sidebar-bg: $white;
$sidebar-icon-size: 24px;
$sidebar-icon-box-size: 48px;
$sidebar-icon-color: $gray-1200;
$sidebar-icon-hover-color: $gray-400;
$widget-divider: 2px solid $hr-border;
$widget-link-font-size: $font-size-small;
$widget-link-color: $gray-400;
$widget-link-hover-color: $gray-1200;
$widget-color-label-size: 10px;

// Tags
$widget-tags-height: 30px;
$widget-tags-font-size: $font-size-small;
$widget-tags-color: $gray-400;
$widget-tags-hover-color: $gray-1200;
$widget-tags-border-color: $hr-border;
$widget-tags-hover-border-color: $gray-1200;

// == Components
//

// Category Links
$category-link-img-max-width: 220px;
$category-link-font-size: $font-size-base;
$category-link-color: $gray-1200;
$category-link-hover-color: $gray-400;

// Category Tiles
$category-tile-bg: $gray-100;
$category-tile-thumb-max-width: 300px;

// Quotation
$quotation-author-ava-size: 74px;

// Team
$teammate-max-width: 380px;
$teammate-name-font-size: $font-size-base;
$teammate-position-font-size: $font-size-small;
$teammate-position-color: $gray-400;

// Countdown
$countdown-item-box-size: 48px;
$countdown-item-font-size: floor(($font-size-base * 1.375)); // ~22px
$countdown-item-bg-color: $white;
$countdown-label-font-size: floor(($font-size-base * 0.875)); // ~14px

// Features
$feature-title-size: $font-size-base;
$feature-icon-color: $gray-400;
$feature-text-color: $gray-400;
$feature-text-size: $font-size-small;

// Brands
$brand-logo-max-width: 150px;
$brand-text-font-size: $font-size-small;
$brand-text-color: $gray-400;

// Video Popup Button
$video-popup-btn-size: 110px;
$video-popup-icon-size: 44px;
$video-popup-btn-color: $gray-1200;
$video-popup-btn-hover-color: $gray-400;
$video-popup-btn-light-color: $white;

//== Preloader
//

$preloader-size: 60px;
$preloader-screen-bg: $white;

//== Tooltips
//
$tooltip-max-width: 240px;
$tooltip-color: #606060;
$tooltip-bg: #f9f9f9;
$tooltip-opacity: 1;
$tooltip-arrow-width: 8px;
$tooltip-arrow-color: $tooltip-bg;

//== Pagination
//

$pagination-link-font-size: $font-size-small;
$pagination-link-color: $gray-1200;
$pagination-link-active-color: transparentize($pagination-link-color, 0.45);

$pager-link-size: $font-size-small; // ~14px
$pager-link-color: $gray-400;
$pager-link-hover-color: $gray-1200;
$pager-preview-thumb-width: 80px;

//== Carousel
//

$carousel-navs-size: 40px;
$carousel-navs-icon-size: 18px;
$carousel-navs-bg: $white;
$carousel-navs-color: $gray-1200;
$carousel-dots-size: 10px;
$carousel-dots-color: $white;
$carousel-controls-opacity: 0.6;

//== Media queries (responsive breakpoints)
//## Desktop, Tablet, Mobile

$desktop: 'screen and (max-width: 1200px)';
$tablet-land: 'screen and (max-width: 991px)';
$tablet-port: 'screen and (max-width: 767px)';
$mobile-land: 'screen and (max-width: 480px)';
$mobile-port: 'screen and (max-width: 360px)';
$navbar-collapse: 'screen and (max-width: 1199px)';
$navbar-collapse-min: 'screen and (min-width: 1200px)';
