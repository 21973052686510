// !! Note !! If you add a new colour variable, you should include it in
// the Storybook:
// 1. Add the colour to exports in the /storybook/Colors/index.module.scss.
// 2. Add the colour to view in the /storybook/Colors/Colors.stories.mdx.

//////////////////
///// COLORS /////
//////////////////
$red-1200: #A10D0D;
$red-1000: #B90E0E;
$red-800: #E41111;
$red-600: #E84646;
$red-400: #ED7B7B;
$red-200: #F1AFAF;
$red-100: #F5E4E4;
$red-50: #F5EBEB;

$pink-1200: #750029;
$pink-1000: #8E0042;
$pink-800: #B20048;
$pink-600: #D41B66;
$pink-400: #E576A3;
$pink-200: #EEA4C2;
$pink-100: #F6D1E0;
$pink-50: #F6EAEF;

$orange-1200: #764118;
$orange-1000: #A14F0C;
$orange-800: #FF7900;
$orange-600: #FF9433;
$orange-400: #FFAF66;
$orange-200: #FFC999;
$orange-100: #FFE4CC;
$orange-50: #FFF4EB;

$yellow-1200: #987D16;
$yellow-1000: #D3AA08;
$yellow-800: #FFCD03;
$yellow-600: #FFD735;
$yellow-400: #FFE168;
$yellow-200: #FFEB9A;
$yellow-100: #FFF5CD;
$yellow-50: #FFFBEB;

$deep-blue-500: #0D47A1;
$deep-blue-600: #1b4181;

$blue-1200: #10596A;
$blue-1000: #0096BB;
$blue-800: #00AFD8;
$blue-600: #36C0E0;
$blue-400: #6DD1E9;
$blue-200: #B3E7F3;
$blue-100: #D9F3F9;
$blue-50: #EDF7F9;

$slate-1200: #212431;
$slate-1000: #474954;
$slate-800: #6D6E77;
$slate-600: #92949A;
$slate-400: #B8B9BD;
$slate-200: #DEDEE0;
$slate-100: #F0EFED;
$slate-50: #F0F7F7;

$biscuit-1200: #3F4043;
$biscuit-1000: #636467;
$biscuit-800: #A6A59D;
$biscuit-600: #E8E6D4;
$biscuit-400: #F1EFE5;
$biscuit-200: #F7F5EF;
$biscuit-100: #FCFBF9;
$biscuit-50: #FCFBF9;

$green-1200: #00442C;
$green-1000: #005C3B;
$green-800: #00734A;
$green-600: #369171;
$green-400: #6DAF97;
$green-200: #B3D5C9;
$green-100: #D9EAE4;
$green-50: #EDFAF5;

$turquoise-1200: #004440;
$turquoise-1000: #015D59;
$turquoise-800: #007C76;
$turquoise-600: #00B5AD;
$turquoise-400: #6DD5D0;
$turquoise-200: #B3E9E6;
$turquoise-100: #D9F4F3;
$turquoise-50: #F0FFFE;

$gray-1200: #1A1A1A;
$gray-1000: #4D4D4D;
$gray-800: #808080;
$gray-600: #999999;
$gray-400: #B2B2B2;
$gray-200: #DCDCDC;
$gray-100: #EDEDED;
$gray-50: #F2F2F2;

$black: #000000;
$white: #ffffff;

//////////////////////////////
///// TRANSPARENT COLORS /////
//////////////////////////////
$black-50: rgba(0, 0, 0, 0.5);

///////////////////
///// SHADOWS /////
///////////////////
$shadow1: 1px 1px 4px 0 $black-50;
$shadow2: 0 2px 4px 0 $black-50;
$shadow-soft:
        0px 1px 2px 0px #0000000F,
        0px 5px 5px 0px #0000000D,
        0px 10px 6px 0px #00000008,
        0px 18px 7px 0px #00000003,
        0px 28px 8px 0px #00000000;

///////////////////
///// SPECIAL COLORS /////
///////////////////
$color-paypal: #FFD140;