@import '../../styles/gifts/sass/basics';

.shipping-info-message {
  margin-top: 24px;
  margin-bottom: 40px;
  padding: 12px 16px;
  border: 1px solid $slate-100;
  border-radius: 4px;
  display: flex;
  gap: 14px;
  width: 100%;

  &__icon {
    flex-shrink: 0;
    align-self: center;
    justify-self: center;
    width: 24px;
    height: 24px;
  }

  &__message {
    color: $slate-800;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: 14px;
    margin-bottom: 4px;
    line-height: $line-height-normal;
  }

  &__description {
    font-size: 12px;
    line-height: $line-height-large;
  }
}