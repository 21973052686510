@import '../../../../styles/basics';

.field-type-text[class*='field-style-pill'] {
  position: relative;

  // Hide vertical red line.
  &.error {
    border-left: none;
    padding-left: inherit;
  }

  input {
    color: $slate-1200;
    width: 100%;
    font-size: 16px;
    border: 1px solid $gray-1000;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    padding: 10px 24px;
    line-height: 22px;
  }

  label {
    color: $gray-1000;
    position: absolute;
    left: 24px;
    top: 0;
    font-size: 12px;
  }

  &.field-style-pill-pink {
    input {
      border-color: $pink-800;
    }

    label {
      color: $pink-800;
    }
  }

  &.field-style-pill-green {
    input {
      border-color: $turquoise-1000;
    }

    label {
      color: $turquoise-1000;
    }
  }

  &.field-style-pill-white {
    input {
      border-color: $white;
    }

    label {
      color: $white !important;
    }

    [data-component="field-error"] {
      color: $white;
      svg path {
        fill: $white;
      }
    }
  }

  &.error {
    input {
      border-color: $red-1000;
    }

    label {
      color: $red-1000;
    }

    [data-component="field-error"] {
      display: block;
      padding-left: 24px;
    }
  }

  > div > svg {
    position: absolute;
    left: 14px;
    top: 13px;
    width: 20px;
    height: 20px;

    path {
      fill: $slate-1000;
    }
  }


  &.with-icon {
    input {
      padding-left: 40px;
    }
  }

  &.with-label {
    margin-top: 20px;
  }
}
