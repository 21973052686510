@import '../../../../styles/basics';

.field-type-text.field-style-normal {
  position: relative;

  label {
    font-weight: $font-weight-bold;
    cursor: text;
    transition: all 0.2s;
    color: $black;
    font-size: 16px;
    margin-bottom: 5px;

    .validation {
      font-size: 14px;
      color: $slate-1000;
      font-weight: $font-weight-regular;
    }
  }

  > div > svg {
    position: absolute;
    left: 10px;
    top: 13px;
    width: 20px;
    height: 20px;
    color: $slate-1000;
  }

  input,
  textarea {
    color: $slate-1200;
    width: 100%;
    font-size: 16px;
    border: 1px solid $slate-1000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 4px;
    padding: 10px 34px 10px 14px;
    line-height: 24px;
    transition: border-color 0.3s;
    overflow: hidden;
    resize: none;
    /* Remove inner shadow on iphone */
    -webkit-appearance: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset, 0 0 0 4px $turquoise-100 !important;
    }

    &:focus {
      border-width: 2px;
      margin: -1px;
      outline: none;
      border-color: $turquoise-1000;
      box-shadow: 0 0 0 3px $turquoise-100;
    }

    &:hover:not(:focus) {
      border-color: $slate-1000;
    }
  }
  textarea:focus {
    margin: -1px 0 1px -1px;
  }

  &.has-value:not(.active):not(.error) {
    input,
    textarea {
      border-color: $turquoise-1000;
    }

    > div > svg {
      color: $turquoise-1000;
    }
  }

  &.active {
    label {
      color: $turquoise-1000;
      .validation {
        color: $turquoise-1000;
      }
    }
  }

  textarea {
    min-height: 96px;
  }

  &.error {
    > label {
      color: $red-1000;
      .validation {
        color: $red-1000;
      }
    }

    input,
    textarea {
      border-color: $red-1000;
      box-shadow: none;

      &:hover {
        border-color: $red-1000;
      }
      &:focus {
        box-shadow: 0 0 0 3px $red-100;
      }
    }

    > div > svg {
      position: absolute;
      color: $red-1000;
    }
  }

  &.with-icon {
    input {
      padding-left: 38px;
    }
  }
}
