/// FONT STACKS ///
// Main font for the site is IBM Plex Sans.
$font-family-stack-sans: 'IBM Plex Sans', 'Helvetica', 'Arial', 'sans-serif';

// Only for country counter.
$font-family-stack-khand-medium: 'Khand Medium', 'Helvetica', 'Arial';

$font-family-ibm-plex-serif-activism: 'IBM Plex Serif', 'Helvetica', 'Arial', 'sans-serif';

// If introducing new font weight, make sure it's loaded from Google Fonts.
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$line-height-small: 1.1;
$line-height-normal: 1.3;
$line-height-large: 1.5;